<template>
  <div class="syllavus-A-cotainer">
    <SyllabusPage :wordList="wordListData" :isPageA="false" />
  </div>
</template>

<script>
import SyllabusPage from "@/components/Course/CoursePage/SyllabusPage";

export default {
  name: "SyllabusPage-lesson-1-A",
  data() {
    return {
      wordListData: [
        {
          title: "复习巩固",
          isReview: true,
          contentList: [
            [
              {
                pinyin: "kǒu",
                hanzi: "口",
                symbol: "",
                group: 0,
                line: 0,
                index: 1,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 2,
                symbolType: 1,
              },
              {
                pinyin: "rén",
                hanzi: "人",
                symbol: "",
                group: 0,
                line: 0,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 4,
                symbolType: 1,
              },
              {
                pinyin: "hé",
                hanzi: "和",
                symbol: "",
                group: 0,
                line: 0,
                index: 5,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 6,
                symbolType: 1,
              },
              {
                pinyin: "jiějie",
                hanzi: "姐姐",
                symbol: "",
                group: 0,
                line: 0,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 0,
                line: 0,
                index: 8,
                symbolType: 1,
              },
              {
                pinyin: "dìdi",
                hanzi: "弟弟",
                symbol: "",
                group: 0,
                line: 0,
                index: 9,
                symbolType: 0,
              },

              // symbolType: 0word 1、 2： 3 “” 4！ 5…… 6。
            ],
            [
              {
                pinyin: "jùxíng",
                hanzi: "句型",
                symbol: "",
                group: 0,
                line: 1,
                index: 0,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "：“",
                group: 0,
                line: 1,
                index: 1,
                symbolType: 2,
              },
              {
                pinyin: "Wǒ",
                hanzi: "我",
                symbol: "",
                group: 1,
                line: 1,
                index: 2,
                symbolType: 0,
              },
              {
                pinyin: "jiā",
                hanzi: "家",
                symbol: "",
                group: 1,
                line: 1,
                index: 3,
                symbolType: 0,
              },
              {
                pinyin: "yǒu",
                hanzi: "有",
                symbol: "",
                group: 1,
                line: 1,
                index: 4,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "……",
                group: 1,
                line: 1,
                index: 5,
                symbolType: 5,
              },
              {
                pinyin: "kǒu",
                hanzi: "口",
                symbol: "",
                group: 1,
                line: 1,
                index: 6,
                symbolType: 0,
              },
              {
                pinyin: "rén",
                hanzi: "人",
                symbol: "",
                group: 1,
                line: 1,
                index: 7,
                symbolType: 0,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "、",
                group: 1,
                line: 1,
                index: 8,
                symbolType: 1,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "……",
                group: 1,
                line: 1,
                index: 9,
                symbolType: 5,
              },

              {
                pinyin: "hé",
                hanzi: "和",
                symbol: "",
                group: 1,
                line: 1,
                index: 10,
                symbolType: 0,
              },

              {
                pinyin: "",
                hanzi: "",
                symbol: "……",
                group: 1,
                line: 1,
                index: 11,
                symbolType: 5,
              },
              {
                pinyin: "",
                hanzi: "",
                symbol: "”",
                group: 1,
                line: 1,
                index: 12,
                symbolType: 7,
              },
            ],
          ],
        },
        {
          title: "我知道",
          isReview: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            // symbolType: 0word 1、 2： 3 “ 4！ 5…… 6。7”

            {
              pinyin: "jīběn",
              hanzi: "基本",
              symbol: "",
              group: 1,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "bǐhuà",
              hanzi: "笔画",
              symbol: "",
              group: 1,
              index: 1,
              symbolType: 0,
            },
            {
              pinyin: "",
              hanzi: "",
              symbol: "",
              group: 1,
              index: 2,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-14-piedian.svg"),
              symbolType: 8,
            },

            {
              pinyin: "de",
              hanzi: "的",
              symbol: "",
              group: 1,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "míngchēng",
              hanzi: "名称",
              symbol: "",
              group: 1,
              index: 4,
              symbolType: 0,
            },
            {
              pinyin: "jí",
              hanzi: "及",
              symbol: "",
              group: 1,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 1,
              index: 6,
              symbolType: 0,
            },
            {
              pinyin: "guīzé",
              hanzi: "规则",
              symbol: "",
              group: 1,
              index: 7,
              symbolType: 0,
            },
          ],
        },
        {
          title: "我会写",
          isReview: false,
          content: [
            // {
            //   pinyin: "nǐ",
            //   hanzi: "你",
            //   symbol: "",
            //   id: 1,
            //   symbolType: 0,
            // },

            {
              pinyin: "ànzhào",
              hanzi: "按照",
              symbol: "",
              group: 2,
              index: 0,
              symbolType: 0,
            },
            {
              pinyin: "zhèngquè",
              hanzi: "正确",
              symbol: "",
              group: 2,
              index: 1,
              symbolType: 0,
            },

            {
              pinyin: "bǐshùn",
              hanzi: "笔顺",
              symbol: "",
              group: 2,
              index: 2,
              symbolType: 0,
            },
            {
              pinyin: "shūxiě",
              hanzi: "书写",
              symbol: "",
              group: 2,
              index: 3,
              symbolType: 0,
            },
            {
              pinyin: "kǒu",
              hanzi: "",
              symbol: "",
              group: 2,
              index: 4,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-14-kou.svg"),
              symbolType: 8,
            },
            {
              pinyin: "hé",
              hanzi: "和",
              symbol: "",
              group: 2,
              index: 5,
              symbolType: 0,
            },
            {
              pinyin: "rén",
              hanzi: "",
              symbol: "",
              group: 2,
              index: 6,
              isHavePinyin: true,
              imgUrl: require("@/assets/img/11-Syllabus/lesson-14-ren.svg"),
              symbolType: 8,
            },
          ],
        },
      ],
    };
  },
  components: {
    SyllabusPage,
  },
};
</script>

<style lang="scss" scoped>
.syllavus-A-cotainer {
  width: 100%;
  height: 100%;
}
</style>